/*

Copyright 2021-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-context-menu2-virtual-target {
  position: fixed;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-popover2 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform: scale(1);
  border-radius: 2px;
  display: inline-block;
  z-index: 20;
}
.bp4-popover2 .bp4-popover2-arrow {
  height: 30px;
  position: absolute;
  width: 30px;
}
.bp4-popover2 .bp4-popover2-arrow::before {
  height: 20px;
  margin: 5px;
  width: 20px;
}
.bp4-popover2 .bp4-popover2-content {
  background: #ffffff;
}
.bp4-popover2 .bp4-popover2-content,
.bp4-popover2 .bp4-heading {
  color: inherit;
}
.bp4-popover2 .bp4-popover2-arrow::before {
  box-shadow: 1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp4-popover2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: 0.1;
}
.bp4-popover2 .bp4-popover2-arrow-fill {
  fill: #ffffff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-popover2 .bp4-popover2-arrow-fill {
    fill: buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-popover2 {
    border: 1px solid buttonborder;
  }
}
.bp4-popover2-enter > .bp4-popover2, .bp4-popover2-appear > .bp4-popover2 {
  transform: scale(0.3);
}
.bp4-popover2-enter-active > .bp4-popover2, .bp4-popover2-appear-active > .bp4-popover2 {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-popover2-exit > .bp4-popover2 {
  transform: scale(1);
}
.bp4-popover2-exit-active > .bp4-popover2 {
  transform: scale(0.3);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-popover2 .bp4-popover2-content {
  border-radius: 2px;
  position: relative;
}
.bp4-popover2.bp4-popover2-content-sizing .bp4-popover2-content {
  max-width: 350px;
  padding: 20px;
}
.bp4-popover2-target + .bp4-overlay .bp4-popover2.bp4-popover2-content-sizing {
  width: 350px;
}
.bp4-popover2.bp4-minimal {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
}
.bp4-popover2.bp4-minimal .bp4-popover2-arrow {
  display: none;
}
.bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
}
.bp4-popover2-enter > .bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-appear > .bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
}
.bp4-popover2-enter-active > .bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-appear-active > .bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-exit > .bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
}
.bp4-popover2-exit-active > .bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2.bp4-popover2-match-target-width {
  width: 100%;
}
.bp4-popover2.bp4-dark, .bp4-dark .bp4-popover2 {
  box-shadow: 0 0 0 1px hsl(215, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-popover2.bp4-dark .bp4-popover2-content, .bp4-dark .bp4-popover2 .bp4-popover2-content {
  background: #2f343c;
}
.bp4-popover2.bp4-dark .bp4-popover2-content,
.bp4-popover2.bp4-dark .bp4-heading, .bp4-dark .bp4-popover2 .bp4-popover2-content,
.bp4-dark .bp4-popover2 .bp4-heading {
  color: inherit;
}
.bp4-popover2.bp4-dark .bp4-popover2-arrow::before, .bp4-dark .bp4-popover2 .bp4-popover2-arrow::before {
  box-shadow: 0 0 0 1px #777a7e, 1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp4-popover2.bp4-dark .bp4-popover2-arrow-border, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: 0.2;
}
.bp4-popover2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-fill {
  fill: #2f343c;
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-popover2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-fill {
    fill: buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-popover2.bp4-dark, .bp4-dark .bp4-popover2 {
    border: 1px solid buttonborder;
  }
}

.bp4-popover2-arrow::before {
  border-radius: 1px;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.bp4-overlay-backdrop.bp4-popover2-backdrop {
  background: rgba(255, 255, 255, 0);
}

.bp4-popover2-transition-container {
  opacity: 1;
  display: flex;
  z-index: 20;
}
.bp4-popover2-transition-container.bp4-popover2-enter, .bp4-popover2-transition-container.bp4-popover2-appear {
  opacity: 0;
}
.bp4-popover2-transition-container.bp4-popover2-enter-active, .bp4-popover2-transition-container.bp4-popover2-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-transition-container.bp4-popover2-exit {
  opacity: 1;
}
.bp4-popover2-transition-container.bp4-popover2-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-transition-container:focus {
  outline: none;
}
.bp4-popover2-transition-container.bp4-popover2-leave .bp4-popover2-content {
  pointer-events: none;
}

span.bp4-popover2-target {
  display: inline-block;
}

.bp4-popover2-target.bp4-fill {
  width: 100%;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
.bp4-button-group:not(.bp4-minimal) > .bp4-popover2-target:not(:first-child) .bp4-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp4-button-group:not(.bp4-minimal) > .bp4-popover2-target:not(:last-child) .bp4-button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
.bp4-button-group .bp4-popover2-target {
  display: flex;
  flex: 1 1 auto;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:first-child .bp4-button {
  border-radius: 2px 2px 0 0;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:last-child .bp4-button {
  border-radius: 0 0 2px 2px;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:not(:last-child) .bp4-button {
  margin-bottom: -1px;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-control-group .bp4-popover2-target {
  border-radius: inherit;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
label.bp4-label .bp4-popover2-target {
  display: block;
  margin-top: 5px;
  text-transform: none;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-submenu .bp4-popover2-target {
  display: block;
}
.bp4-submenu.bp4-popover2 {
  box-shadow: none;
  padding: 0 5px;
}
.bp4-submenu.bp4-popover2 > .bp4-popover2-content {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-submenu.bp4-popover2, .bp4-submenu.bp4-popover2.bp4-dark {
  box-shadow: none;
}
.bp4-dark .bp4-submenu.bp4-popover2 > .bp4-popover2-content, .bp4-submenu.bp4-popover2.bp4-dark > .bp4-popover2-content {
  box-shadow: 0 0 0 1px hsl(215, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-tree-node-secondary-label .bp4-popover2-target {
  align-items: center;
  display: flex;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-tooltip2 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform: scale(1);
  color: #f6f7f9;
}
.bp4-tooltip2 .bp4-popover2-arrow {
  height: 22px;
  position: absolute;
  width: 22px;
}
.bp4-tooltip2 .bp4-popover2-arrow::before {
  height: 14px;
  margin: 4px;
  width: 14px;
}
.bp4-tooltip2 .bp4-popover2-content {
  background: #404854;
}
.bp4-tooltip2 .bp4-popover2-content,
.bp4-tooltip2 .bp4-heading {
  color: #f6f7f9;
}
.bp4-tooltip2 .bp4-popover2-arrow::before {
  box-shadow: 1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp4-tooltip2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: 0.1;
}
.bp4-tooltip2 .bp4-popover2-arrow-fill {
  fill: #404854;
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-tooltip2 .bp4-popover2-arrow-fill {
    fill: buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-tooltip2 {
    border: 1px solid buttonborder;
  }
}
.bp4-popover2-enter > .bp4-tooltip2, .bp4-popover2-appear > .bp4-tooltip2 {
  transform: scale(0.8);
}
.bp4-popover2-enter-active > .bp4-tooltip2, .bp4-popover2-appear-active > .bp4-tooltip2 {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-exit > .bp4-tooltip2 {
  transform: scale(1);
}
.bp4-popover2-exit-active > .bp4-tooltip2 {
  transform: scale(0.8);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-tooltip2 .bp4-text-muted {
  color: #abb3bf;
}
.bp4-tooltip2 .bp4-text-disabled {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-tooltip2 .bp4-running-text hr {
  border-color: rgba(255, 255, 255, 0.2);
}
.bp4-tooltip2 a {
  color: #8abbff;
}
.bp4-tooltip2 a:hover {
  color: #8abbff;
}
.bp4-tooltip2 a .bp4-icon, .bp4-tooltip2 a .bp4-icon-standard, .bp4-tooltip2 a .bp4-icon-large {
  color: inherit;
}
.bp4-tooltip2 a code {
  color: inherit;
}
.bp4-tooltip2 .bp4-code,
.bp4-tooltip2 .bp4-running-text code {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color: #abb3bf;
}
a > .bp4-tooltip2 .bp4-code,
a > .bp4-tooltip2 .bp4-running-text code {
  color: inherit;
}
.bp4-tooltip2 .bp4-code-block,
.bp4-tooltip2 .bp4-running-text pre {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color: #f6f7f9;
}
.bp4-tooltip2 .bp4-code-block > code,
.bp4-tooltip2 .bp4-running-text pre > code {
  background: none;
  box-shadow: none;
  color: inherit;
}
.bp4-tooltip2 .bp4-key,
.bp4-tooltip2 .bp4-running-text kbd {
  background: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
  color: #abb3bf;
}
.bp4-tooltip2 .bp4-icon.bp4-intent-primary, .bp4-tooltip2 .bp4-icon-standard.bp4-intent-primary, .bp4-tooltip2 .bp4-icon-large.bp4-intent-primary {
  color: #8abbff;
}
.bp4-tooltip2 .bp4-icon.bp4-intent-success, .bp4-tooltip2 .bp4-icon-standard.bp4-intent-success, .bp4-tooltip2 .bp4-icon-large.bp4-intent-success {
  color: #72ca9b;
}
.bp4-tooltip2 .bp4-icon.bp4-intent-warning, .bp4-tooltip2 .bp4-icon-standard.bp4-intent-warning, .bp4-tooltip2 .bp4-icon-large.bp4-intent-warning {
  color: #fbb360;
}
.bp4-tooltip2 .bp4-icon.bp4-intent-danger, .bp4-tooltip2 .bp4-icon-standard.bp4-intent-danger, .bp4-tooltip2 .bp4-icon-large.bp4-intent-danger {
  color: #fa999c;
}
.bp4-tooltip2 .bp4-popover2-content {
  padding: 10px 12px;
}
.bp4-tooltip2.bp4-compact .bp4-popover2-content {
  line-height: 1rem;
  padding: 5px 7px;
}
.bp4-tooltip2.bp4-compact .bp4-code {
  vertical-align: text-bottom;
}
.bp4-tooltip2.bp4-popover2-placement-top .bp4-popover2-arrow {
  transform: translateY(-3px);
}
.bp4-tooltip2.bp4-popover2-placement-left .bp4-popover2-arrow {
  transform: translateX(-3px);
}
.bp4-tooltip2.bp4-popover2-placement-bottom .bp4-popover2-arrow {
  transform: translateY(3px);
}
.bp4-tooltip2.bp4-popover2-placement-right .bp4-popover2-arrow {
  transform: translateX(3px);
}
.bp4-tooltip2.bp4-dark, .bp4-dark .bp4-tooltip2 {
  box-shadow: 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-tooltip2.bp4-dark .bp4-popover2-content, .bp4-dark .bp4-tooltip2 .bp4-popover2-content {
  background: #e5e8eb;
}
.bp4-tooltip2.bp4-dark .bp4-popover2-content,
.bp4-tooltip2.bp4-dark .bp4-heading, .bp4-dark .bp4-tooltip2 .bp4-popover2-content,
.bp4-dark .bp4-tooltip2 .bp4-heading {
  color: #404854;
}
.bp4-tooltip2.bp4-dark .bp4-popover2-arrow::before, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow::before {
  box-shadow: 1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp4-tooltip2.bp4-dark .bp4-popover2-arrow-border, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: 0.2;
}
.bp4-tooltip2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-fill {
  fill: #e5e8eb;
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-tooltip2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-fill {
    fill: buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-tooltip2.bp4-dark, .bp4-dark .bp4-tooltip2 {
    border: 1px solid buttonborder;
  }
}
.bp4-tooltip2.bp4-dark .bp4-text-muted, .bp4-dark .bp4-tooltip2 .bp4-text-muted {
  color: #5f6b7c;
}
.bp4-tooltip2.bp4-dark .bp4-text-disabled, .bp4-dark .bp4-tooltip2 .bp4-text-disabled {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-tooltip2.bp4-dark .bp4-running-text hr, .bp4-dark .bp4-tooltip2 .bp4-running-text hr {
  border-color: rgba(17, 20, 24, 0.15);
}
.bp4-tooltip2.bp4-dark a, .bp4-dark .bp4-tooltip2 a {
  color: #215db0;
}
.bp4-tooltip2.bp4-dark a:hover, .bp4-dark .bp4-tooltip2 a:hover {
  color: #215db0;
}
.bp4-tooltip2.bp4-dark a .bp4-icon, .bp4-tooltip2.bp4-dark a .bp4-icon-standard, .bp4-tooltip2.bp4-dark a .bp4-icon-large, .bp4-dark .bp4-tooltip2 a .bp4-icon, .bp4-dark .bp4-tooltip2 a .bp4-icon-standard, .bp4-dark .bp4-tooltip2 a .bp4-icon-large {
  color: inherit;
}
.bp4-tooltip2.bp4-dark a code, .bp4-dark .bp4-tooltip2 a code {
  color: inherit;
}
.bp4-tooltip2.bp4-dark .bp4-code,
.bp4-tooltip2.bp4-dark .bp4-running-text code, .bp4-dark .bp4-tooltip2 .bp4-code,
.bp4-dark .bp4-tooltip2 .bp4-running-text code {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color: #5f6b7c;
}
a > .bp4-tooltip2.bp4-dark .bp4-code,
a > .bp4-tooltip2.bp4-dark .bp4-running-text code, a > .bp4-dark .bp4-tooltip2 .bp4-code,
a > .bp4-dark .bp4-tooltip2 .bp4-running-text code {
  color: #2d72d2;
}
.bp4-tooltip2.bp4-dark .bp4-code-block,
.bp4-tooltip2.bp4-dark .bp4-running-text pre, .bp4-dark .bp4-tooltip2 .bp4-code-block,
.bp4-dark .bp4-tooltip2 .bp4-running-text pre {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.15);
  color: #1c2127;
}
.bp4-tooltip2.bp4-dark .bp4-code-block > code,
.bp4-tooltip2.bp4-dark .bp4-running-text pre > code, .bp4-dark .bp4-tooltip2 .bp4-code-block > code,
.bp4-dark .bp4-tooltip2 .bp4-running-text pre > code {
  background: none;
  box-shadow: none;
  color: inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-tooltip2.bp4-dark .bp4-code-block,
  .bp4-tooltip2.bp4-dark .bp4-running-text pre, .bp4-dark .bp4-tooltip2 .bp4-code-block,
  .bp4-dark .bp4-tooltip2 .bp4-running-text pre {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}
.bp4-tooltip2.bp4-dark .bp4-key,
.bp4-tooltip2.bp4-dark .bp4-running-text kbd, .bp4-dark .bp4-tooltip2 .bp4-key,
.bp4-dark .bp4-tooltip2 .bp4-running-text kbd {
  background: #ffffff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  color: #5f6b7c;
}
.bp4-tooltip2.bp4-dark .bp4-icon.bp4-intent-primary, .bp4-tooltip2.bp4-dark .bp4-icon-standard.bp4-intent-primary, .bp4-tooltip2.bp4-dark .bp4-icon-large.bp4-intent-primary, .bp4-dark .bp4-tooltip2 .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-tooltip2 .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-tooltip2 .bp4-icon-large.bp4-intent-primary {
  color: #215db0;
}
.bp4-tooltip2.bp4-dark .bp4-icon.bp4-intent-success, .bp4-tooltip2.bp4-dark .bp4-icon-standard.bp4-intent-success, .bp4-tooltip2.bp4-dark .bp4-icon-large.bp4-intent-success, .bp4-dark .bp4-tooltip2 .bp4-icon.bp4-intent-success, .bp4-dark .bp4-tooltip2 .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-tooltip2 .bp4-icon-large.bp4-intent-success {
  color: #1c6e42;
}
.bp4-tooltip2.bp4-dark .bp4-icon.bp4-intent-warning, .bp4-tooltip2.bp4-dark .bp4-icon-standard.bp4-intent-warning, .bp4-tooltip2.bp4-dark .bp4-icon-large.bp4-intent-warning, .bp4-dark .bp4-tooltip2 .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-tooltip2 .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-tooltip2 .bp4-icon-large.bp4-intent-warning {
  color: #935610;
}
.bp4-tooltip2.bp4-dark .bp4-icon.bp4-intent-danger, .bp4-tooltip2.bp4-dark .bp4-icon-standard.bp4-intent-danger, .bp4-tooltip2.bp4-dark .bp4-icon-large.bp4-intent-danger, .bp4-dark .bp4-tooltip2 .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-tooltip2 .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-tooltip2 .bp4-icon-large.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-tooltip2.bp4-intent-primary .bp4-popover2-content {
  background: #2d72d2;
  color: #ffffff;
}
.bp4-tooltip2.bp4-intent-primary .bp4-popover2-arrow-fill {
  fill: #2d72d2;
}
.bp4-tooltip2.bp4-intent-success .bp4-popover2-content {
  background: #238551;
  color: #ffffff;
}
.bp4-tooltip2.bp4-intent-success .bp4-popover2-arrow-fill {
  fill: #238551;
}
.bp4-tooltip2.bp4-intent-warning .bp4-popover2-content {
  background: #c87619;
  color: #ffffff;
}
.bp4-tooltip2.bp4-intent-warning .bp4-popover2-arrow-fill {
  fill: #c87619;
}
.bp4-tooltip2.bp4-intent-danger .bp4-popover2-content {
  background: #cd4246;
  color: #ffffff;
}
.bp4-tooltip2.bp4-intent-danger .bp4-popover2-arrow-fill {
  fill: #cd4246;
}

.bp4-tooltip2-indicator {
  border-bottom: dotted 1px;
  cursor: help;
}